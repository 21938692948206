import { defineNuxtRouteMiddleware } from '#app';
import useAuthStore from '~/stores/auth';
import { apiFetch } from '#imports';
import type { UserAttributes } from '~/types';

export default defineNuxtRouteMiddleware(async (to) => {
    if (import.meta.server) return;

    const authStore = useAuthStore();

    if (!authStore.isAuthenticated) {
        authStore.user = await apiFetch<UserAttributes>('users')
            .catch(() => null);
    }

    if (!authStore.isAuthenticated) {
        if (to.path !== '/admin') {
            localStorage.setItem('initialTarget', JSON.stringify(to));
        }

        return navigateTo({ path: '/login', query: { loggedOut: 1 } });
    }
});
